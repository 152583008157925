<template>
  <div class="home">
    <div class="top-page">
      <img class="logo" src="../../assets/logo.png" alt="图片丢了嘿~">
      <div class="down-btn" @click="downloadHandle()">
        <div class="title">
          {{ $t('downloadTitle') }}
        </div>
      </div>
    </div>
    <AboutUs></AboutUs>
    <Trait></Trait>
    <Future></Future>
  </div>
</template>

<script>
import AboutUs from '@/views/home/AboutUs'
import Trait from '@/views/home/Trait'
import Future from '@/views/home/Future'

export default {
  name: 'Home',
  components: {
    AboutUs,
    Trait,
    Future
  },
  methods: {
    downloadHandle () {
      this.$router.push({ name: 'Download' })
    }
  }
}
</script>
<style scoped lang="scss">
.home {  
  .top-page {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to right, #108EE9 20%, #7949EB 100%);
    .logo {
      width: 300px;
    }
    .down-btn {
      width: 150px;
      height: 40px;
      background-color: #ffffff;
      font-size: 18px;
      margin-top: 50px;
      border-radius: 30px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      .title {
        background-image: -webkit-linear-gradient(right, #7949EB 20%, #108EE9 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
</style>
