<template>
  <div class="page">
    <div class="page-left">
      <div class="page-left-body">
        <div class="page-left-body-title">
          {{ $t('trait.wmdtd.wmd') }}
        </div>
        <div class="page-left-body-title">
          {{ $t('trait.wmdtd.td') }}
        </div>
        <div class="page-left-body-content">
          {{ $t('trait.wmdtd.yyes') }}
        </div>
      </div>
    </div>
    <div class="page-right">
      <div class="page-right-head">
        {{ $t('trait.body.title') }}
        <img src="../../assets/arrowright.png" />
      </div>
      <div class="page-right-content">
         {{ $t('trait.body.content') }}
      </div>
      <div class="page-right-list">
        <div class="page-right-list-item">
          <img src="../../assets/zdwy.png" />
          <div class="page-right-list-item-title">
            {{ $t('trait.body.list.mxwy.title') }}
          </div>
          <div class="page-right-list-item-content">
            {{ $t('trait.body.list.mxwy.content') }}
          </div>
        </div>
        <div class="page-right-list-item">
          <img src="../../assets/zdyz.png" />
          <div class="page-right-list-item-title">
            {{ $t('trait.body.list.mxyz.title') }}
          </div>
          <div class="page-right-list-item-content">
             {{ $t('trait.body.list.mxyz.content') }}
          </div>
        </div>
        <div class="page-right-list-item">
          <img src="../../assets/zdsj.png" />
          <div class="page-right-list-item-title">
            {{ $t('trait.body.list.mxsj.title') }}
          </div>
          <div class="page-right-list-item-content">
             {{ $t('trait.body.list.mxsj.content') }}
          </div>
        </div>
        <div class="page-right-list-item">
          <img src="../../assets/zddc.png" />
          <div class="page-right-list-item-title">
            {{ $t('trait.body.list.mxdc.title') }}
          </div>
          <div class="page-right-list-item-content">
             {{ $t('trait.body.list.mxdc.content') }}
          </div>
        </div>
        <div class="page-right-list-item">
          <img src="../../assets/zdzf.png" />
          <div class="page-right-list-item-title">
            {{ $t('trait.body.list.mxzf.title') }}
          </div>
          <div class="page-right-list-item-content">
            {{ $t('trait.body.list.mxzf.content') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Trait',
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
.page {
  display: flex;
  justify-content: space-between;
  width: 100vw;
  min-height: 100vh;
  margin-bottom: 100px;
  .page-left {
    display: flex;
    flex-direction: column;
    width: 35vw;
    height: 100vh;
    .page-left-body {
      margin-left: 5vw;
      .page-left-body-title {
        font-size: 90px;
        font-family: '宋体';
        font-weight: bold;
      }
      .page-left-body-content {
        color:#999999;
        margin-top: 20px;
      }
    }
  }
  .page-right {
    flex: 1;
    margin-left: 30px;
    padding-right: 50px;
    .page-right-head {
      display: flex;
      align-items: center;
      color: rgba(31, 26, 29, 1);
      font-size: 40px;
      font-weight: 600;
      img {
        width: 40px;
        margin-left: 10px;
      }
    }
    .page-right-content {
      font-size: 30px;
      margin-top: 20px;
    }
    .page-right-list {
      display: flex;
      justify-content: space-around;
      margin-top: 100px;
      .page-right-list-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 50%;
        }
        .page-right-list-item-title {
          margin-top: 30px;
          font-size: 20px;
        }
        .page-right-list-item-content {
          text-align: center;
          padding: 0 20px;
          margin-top: 30px;
          font-size: 18px;
        }
      }
    }
  }
}
</style>
