<template>
  <div class="page">
    <div class="page-left">
      <div class="page-left-body">
        <div class="page-left-body-title">
          {{ $t('future.wmdfz.title') }}
        </div>
        <div class="page-left-body-content">
          {{ $t('future.wmdfz.content') }}
        </div>
      </div>
      <div class="page-left-list">
        <div class="page-left-list-title">
          <img src="../../assets/arrowright.png" />
          <span>{{ $t('future.left.title') }}</span>
        </div>
        <div class="page-left-list-body">
          <div class="page-left-list-body-item">
            <div class="page-left-list-body-item-body">
              <div class="page-left-list-body-item-body-icon">
                01
              </div>
              <div class="page-left-list-body-item-body-title">
                {{ $t('future.left.xtgg.title') }}
              </div>
              <div class="page-left-list-body-item-body-content">
                {{ $t('future.left.xtgg.content') }}
              </div>
            </div>
          </div>
          <div class="page-left-list-body-item">
            <div class="page-left-list-body-item-body">
              <div class="page-left-list-body-item-body-icon">
                02
              </div>
              <div class="page-left-list-body-item-body-title">
                {{ $t('future.left.gwpx.title') }}
              </div>
              <div class="page-left-list-body-item-body-content">
                {{ $t('future.left.gwpx.content') }}
              </div>
            </div>
          </div>
          <div class="page-left-list-body-item">
            <div class="page-left-list-body-item-body">
              <div class="page-left-list-body-item-body-icon">
                03
              </div>
              <div class="page-left-list-body-item-body-title">
                {{ $t('future.left.zfgm.title') }}
              </div>
              <div class="page-left-list-body-item-body-content">
                {{ $t('future.left.zfgm.content') }}
              </div>
            </div>
          </div>
          <div class="page-left-list-body-item">
            <div class="page-left-list-body-item-body">
              <div class="page-left-list-body-item-body-icon">
                04
              </div>
              <div class="page-left-list-body-item-body-title">
                {{ $t('future.left.sqsp.title') }}
              </div>
              <div class="page-left-list-body-item-body-content">
                {{ $t('future.left.sqsp.content') }}
              </div>
            </div>
          </div>
        </div>
        <div class="page-left-list-body page-left-list-body__active">
          <div class="page-left-list-body-item">
            <div class="page-left-list-body-item-body">
              <div class="page-left-list-body-item-body-icon">
                05
              </div>
              <div class="page-left-list-body-item-body-title">
                {{ $t('future.left.jzfw.title') }}
              </div>
              <div class="page-left-list-body-item-body-content">
                {{ $t('future.left.jzfw.content') }}
              </div>
            </div>
          </div>
          <div class="page-left-list-body-item">
            <div class="page-left-list-body-item-body">
              <div class="page-left-list-body-item-body-icon">
                06
              </div>
              <div class="page-left-list-body-item-body-title">
                {{ $t('future.left.sqyl.title') }}
              </div>
              <div class="page-left-list-body-item-body-content">
                {{ $t('future.left.sqyl.content') }}
              </div>
            </div>
          </div>
          <div class="page-left-list-body-item">
            <div class="page-left-list-body-item-body">
              <div class="page-left-list-body-item-body-icon">
                07
              </div>
              <div class="page-left-list-body-item-body-title">
                {{ $t('future.left.wyjm.title') }}
              </div>
              <div class="page-left-list-body-item-body-content">
                {{ $t('future.left.wyjm.content') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-right">
      <div class="page-right-list">
        <div class="page-right-list-item">
          <img src="../../assets/border-icon.png" />
          <div class="page-right-list-item-body">
            <div class="page-right-list-item-body-icon">
              {{ $t('future.right.pd.icon') }}
            </div>
            <div class="page-right-list-item-body-time">
              2022
            </div>
            <div class="page-right-list-item-body-title">
              {{ $t('future.right.pd.title') }}
            </div>
            <div class="page-right-list-item-body-content">
              {{ $t('future.right.pd.content') }}
            </div>
          </div>
        </div>
        <div class="page-right-list-item">
          <img src="../../assets/border-icon.png" />
          <div class="page-right-list-item-body">
            <div class="page-right-list-item-body-icon">
              {{ $t('future.right.yl.icon') }}
            </div>
            <div class="page-right-list-item-body-time">
              2025—2026
            </div>
            <div class="page-right-list-item-body-title">
              {{ $t('future.right.yl.title') }}
            </div>
            <div class="page-right-list-item-body-content">
              {{ $t('future.right.yl.content') }}
            </div>
          </div>
        </div>
        <div class="page-right-list-item">
          <img src="../../assets/border-icon.png" />
          <div class="page-right-list-item-body">
            <div class="page-right-list-item-body-icon">
              {{ $t('future.right.gs.icon') }}
            </div>
            <div class="page-right-list-item-body-time">
              {{ $t('future.right.gs.time') }}
            </div>
            <div class="page-right-list-item-body-title">
              {{ $t('future.right.gs.title') }}
            </div>
            <div class="page-right-list-item-body-content">
              {{ $t('future.right.gs.content') }}
            </div>
          </div>
        </div>
      </div>
      <div class="page-right-list page-right-list__active">
        <div class="page-right-list-item">
          <img src="../../assets/border-icon.png" />
          <div class="page-right-list-item-body">
            <div class="page-right-list-item-body-icon">
              {{ $t('future.right.ph.icon') }}
            </div>
            <div class="page-right-list-item-body-time">
              2023—2024
            </div>
            <div class="page-right-list-item-body-title">
              {{ $t('future.right.ph.title') }}
            </div>
            <div class="page-right-list-item-body-content">
              {{ $t('future.right.ph.content') }}
            </div>
          </div>
        </div>
        <div class="page-right-list-item">
          <img src="../../assets/border-icon.png" />
          <div class="page-right-list-item-body">
            <div class="page-right-list-item-body-icon">
              {{ $t('future.right.qw.icon') }}
            </div>
            <div class="page-right-list-item-body-time">
              2027—2030
            </div>
            <div class="page-right-list-item-body-title">
              {{ $t('future.right.qw.title') }}
            </div>
            <div class="page-right-list-item-body-content">
              {{ $t('future.right.qw.content') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-border"></div>
  </div>
</template>

<script>
export default {
  name: 'Future'
}
</script>

<style scoped lang="scss">
.page {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  height: auto;
  margin-bottom: 10vh;
  .page-left {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-right: 1vw;
    .page-left-body {
      margin-left: 5vw;
      .page-left-body-title {
        font-size: 90px;
        font-family: '宋体';
        font-weight: bold;
      }
      .page-left-body-content {
        color:#999999;
        margin-top: 1vw;
      }
    }
    .page-left-list {
      margin-top: 6vw;
      .page-left-list-title {
        display: flex;
        flex-direction: column;
        padding-left: 50px;
        margin-bottom: 3vh;
        img {
          width: 40px;
        }
        span {
          font-size: 30px;
          font-weight: 600;
          margin-top: 20px;
        }
      }
      .page-left-list-body {
        position: relative;
        display: flex;
        justify-content: center;
        padding: 0 20px 0 50px;
        margin-left: 20px;
        border-bottom: 1px solid rgba(62, 106, 175, 1);
        .page-left-list-body-item {
          display: flex;
          width: 200px;
          border-left: 1px solid rgba(62, 106, 175, 1);
          padding: 20px 0;
          .page-left-list-body-item-body {
            padding: 30px 10px;
            .page-left-list-body-item-body-icon {
              display: inline-block;
              color: white;
              padding: 5px 20px;
              background: linear-gradient(rgba(62, 106, 175, 1), rgba(109, 35, 141, 1));
              font-size: 18px;
              border-radius: 50vh;
            }
            .page-left-list-body-item-body-title {
              font-size: 20px;
              font-weight: 600;
              margin-top: 10px;
            }
            .page-left-list-body-item-body-content {
              font-size: 16px;
              margin-top: 10px;
            }
          }
        }
      }

      .page-left-list-body__active {
        margin-top: 10px;
        border-bottom: none;
        border-top: 1px solid rgba(109, 35, 141, 1);

        .page-left-list-body-item {
          border-left: 1px solid rgba(62, 106, 175, 1);
        }
      }
    }
  }
  .page-right {
    padding-right: 50px;
    flex: 1;
    .page-right-list {
      display: flex;
      .page-right-list-item {
        display: flex;
        img {
          height: 40vh;
        }
        .page-right-list-item-body {
          box-sizing: border-box;
          padding-top: 100px;
          height: 100%;
          width: 300px;
          .page-right-list-item-body-icon {
            display: inline-block;
            color: rgba(62, 106, 175, 1);
            padding: 5px 10px;
            border: 1px solid rgba(112, 112, 112, 1);
            border-radius: 10px;
          }
          .page-right-list-item-body-title {
            margin-top: 10px;
            font-weight: 600;
          }
          .page-right-list-item-body-time {
            margin-top: 10px;
            color: rgba(109, 35, 141, 1);
          }
          .page-right-list-item-body-content {
            margin-top: 15px;
          }
        }
      }
    }
    .page-right-list__active {
      display: flex;
      justify-content: center;
      img {
        transform: rotate(180deg);
      }
    }
  }
  .page-border {
    position: absolute;
    z-index: -1;
    width: 100vw;
    height: 20px;
    top: 39vh;
    background: radial-gradient(rgba(105, 162, 203, 1) 0%, rgba(255, 255, 255, 1) 100%);
  }
}
</style>
