<template>
  <div class="page">
    <div class="page-left">
      <div class="page-left-body">
        <div class="page-left-body-title">
          {{ $t('aboutUs.gywm') }}
        </div>
        <div class="page-left-body-content">
          {{ $t('aboutUs.hlht') }}
        </div>
      </div>
    </div>
    <div class="page-right">
      <div class="page-right-text">
        {{ $t('aboutUs.introduce') }}
      </div>
      <span class="page-right-button" @click="$router.push({name:'Explore'})">{{ $t('aboutUs.more') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs'
}
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;
  .page-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 45vw;
    height: 100vh;
    .page-left-body {
      margin-left: 5vw;
      .page-left-body-title {
        font-size: 90px;
        font-family: '宋体';
        font-weight: bold;
      }
      .page-left-body-content {
        color:#999999;
        margin-top: 20px;
      }
    }
  }
  .page-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: #BDE3FF;
    width: 40vw;
    height: 50vh;
    padding: 30px 80px 30px 30px;
    .page-right-text {
      text-indent: 32px;
      line-height: 40px;
    }
    .page-right-button {
      align-self: flex-start;
      padding: 10px 20px;
      cursor: pointer;
      color: #FFFFFF;
      background: linear-gradient(to right, #108EE9 20%, #7949EB 100%);
      font-size: 25px;
      margin-top: 40px;
    }
  }
}
</style>
